/* eslint-disable no-console */
import Api from '@/api/Api'
import axios from 'axios'

class HaulingRequestApi extends Api {
  constructor () {
    super('hauling-request', 'hauling-request')
  }

  async setImportSegmentDepth (id, segmentDepthId) {
    // eslint-disable-next-line prefer-const

    const errorText = 'Could not update approval import depth'
    let res
    try {
      res = await axios.get(`${this.endpoint}/approval/${id}/set-import-depth/${segmentDepthId}`)
      if (res.status === 200) {
        res = res.data
      } else if (res.status === 204) {
        console.error(errorText)
      } else {
        console.error(errorText)
        res = false
      }
    } catch (e) {
      console.error(errorText)
      console.error(e)
      res = false
    }
    return res
  }
}

const haulingRequestApi = new HaulingRequestApi()
export default haulingRequestApi
