/* eslint-disable no-console */
import Api from '@/api/Api'

class CompaniesApi extends Api {
  constructor () {
    super('companies', 'companies')
  }
}

const companiesApi = new CompaniesApi()
export default companiesApi
