import axios from '@/plugins/axios'
// import axiosssssssss from 'axios';

export class Api {
  constructor (endpoint, model) {
    // set the endpoint and model
    this.model = model
    this.endpoint = endpoint
  }

  async get (path = null, parameters = []) {
    let list = []
    const errorText = `Could not fetch ${this.model} list`
    let url = `/${this.endpoint}${path ? `/${path}` : ''}`
    if (parameters.length > 0) {
      url += '?'
      let first = true
      parameters.forEach((x) => {
        if (first === false) url += '&'
        url += (`${x.name}=${x.value}`)
        first = false
      })
    }

    await axios.get(url)
      .then((response) => {
        list = response.data
      })
      .catch((error) => {
        if (error.response) {
        // Request made and server responded
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
        } else if (error.request) {
        // The request was made but no response was received
          console.log(error.request)
        } else {
        // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message)
        }
        console.log(errorText)
        list = []
      })
    return list
  }

  async post (data, path = null, config = {}, returnRaw = false) {
    let res = {}
    const errorText = `Could not create ${this.model}`

    await axios.post(`/${this.endpoint}${path ? `/${path}` : ''}`, data, config)
      .then((response) => {
        res = returnRaw ? response : response.data
      })
      .catch((error) => {
        if (error.response) {
        // Request made and server responded
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
          res = returnRaw ? error.response : error.response.data
        } else if (error.request) {
        // The request was made but no response was received
          console.log(error.request)
        } else if (error.code === 'ERR_NETWORK') {
          console.log('hit')
          res.error = 'Connection Error'
        } else {
        // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message)
        }
        console.log(errorText)
        // res = false;
      })
    return res
  }

  async put (data, path = null, config = {}) {
    let res = false
    const errorText = `Could not create ${this.model}`
    await axios.put(`/${this.endpoint}${path ? `/${path}` : ''}`, data, config)
      .then((response) => {
        res = response.data
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request)
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message)
        }
        console.log(errorText)
        res = false
      })

    return res
  }

  async read (config) {
    let list = []
    const errorText = `Could not fetch ${this.model} list`

    await axios.post(`/${this.endpoint}/read`, config)
      .then((response) => {
        list = response.data
      })
      .catch((error) => {
        if (error.response) {
        // Request made and server responded
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
        } else if (error.request) {
        // The request was made but no response was received
          console.log(error.request)
        } else {
        // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message)
        }
        console.log(errorText)
        list = []
      })
    return list
  }

  async patch (data, path = null) {
    let res = false
    const errorText = `Could not patch ${this.model}`

    await axios.patch(`/${this.endpoint}${path ? `/${path}` : ''}`, data)
      .then((response) => {
        res = response.data
      })
      .catch((error) => {
        if (error.response) {
        // Request made and server responded
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
        } else if (error.request) {
        // The request was made but no response was received
          console.log(error.request)
        } else {
        // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message)
        }
        console.log(errorText)
        res = false
      })
    return res
  }

  async delete (path = null, id = null) {
    let res = false
    const errorText = `Could not delete ${this.model}`
    const url = `/${this.endpoint}${path ? `/${path}` : ''}${id ? `/${id}` : ''}`
    await axios.delete(url)
      .then((response) => {
        res = response.data
      })
      .catch((error) => {
        if (error.response) {
        // Request made and server responded
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
        } else if (error.request) {
        // The request was made but no response was received
          console.log(error.request)
        } else {
        // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message)
        }
        console.log(errorText)
        res = false
      })
    return res
  }
}

export default Api
