/* eslint-disable consistent-return */
/* eslint-disable no-async-promise-executor */
/* eslint-disable no-param-reassign */
import api from '@/api/ApiFactories'
import { createPaginationObject } from '@/helpers/PaginationHelper.js'

export default {
  namespaced: true,

  state: () => ({
    companies: []
  }),
  mutations: {
    storeCompanies (state, companies) {
      state.companies = companies
    },
    clearData (state) {
      state.companies = []
    }
  },
  actions: {
    setCompanies ({ commit }, companies) {
      commit('storeCompanies', companies)
    },
    async loadCompanies ({ commit }) {
      const res = await api.companies.read(createPaginationObject(null, null, [{ name: 'Own', values: [] }]))
      if (res && res.companies && res.companies.length) {
        commit('storeCompanies', res.companies)
      }
    }
  },
  getters: {
  }
}
