/* eslint-disable consistent-return */
/* eslint-disable no-async-promise-executor */
/* eslint-disable no-param-reassign */
import api from '@/api/ApiFactories'

export default {
  namespaced: true,

  state: () => ({
    unitsOfMeasurement: []
  }),
  mutations: {
    storeUnitsOfMeasurement (state, uoms) {
      state.unitsOfMeasurement = uoms
    },
    clearData (state) {
      state.unitsOfMeasurement = []
    }
  },
  actions: {
    async loadUnitsOfMeasurement ({ commit, state }) {
      if (state.unitsOfMeasurement && state.unitsOfMeasurement.length) return
      const uoms = await api.uom.get()
      commit('storeUnitsOfMeasurement', uoms)
    }
  },
  getters: {
  }
}
