<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="300"
  >
    <v-card
      color="primary"
    >
      <v-card-text>
        {{ message }}
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapGetters } from 'vuex'// import { mapGetters, mapState, mapMutations } from 'vuex';
import router from '@/router'

export default {
  components: {
  },
  data: () => ({
    dialog: false
  }),
  created () {
  },
  props: {
    message: {
      type: String,
      default: 'Loading'
    }
  },
  computed: {
    ...mapState({
      logo: (state) => state.app.isNavigationMinified
    }),
    ...mapGetters({
      logo: 'app/logo',
      isAuthenticated: 'auth/isAuthenticated'
    }),
    moduleIcon () {
      return router.currentRoute.value.meta.icon || 'mdi-magnify'
    },
    moduleName () {
      return router.currentRoute.value.name || ' '
    }
  },
  methods: {
    loading (enable) {
      this.dialog = enable
    }
  }
}

// const emitter: any = inject('emitter')
// emitter.on('loading', loading)
</script>
