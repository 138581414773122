<template>
  <!-- TODO: might have to move the icon in a row -->
  <v-row>
    <v-col class="d-flex justify-end align-center">
      <v-icon
        :color="isCompact == true? 'grey lighten-2': '#00ACCD'"
      >
        {{ isCompact != true ? 'mdi-brightness-7': isCompact == true && isDarkMode ? 'mdi-brightness-7' : 'mdi-brightness-3' }}
      </v-icon>
    </v-col>
    <v-col class="d-flex justify-center align-center">
      <v-switch
        v-model="isDarkMode"
        :class="`${(isAbsolute == true ? 'hover': '')} center-switch`"
        :color="isCompact == true? 'grey lighten-2': '#00ACCD'"
        inset
        inline
        class="mx-0 mr-0"
      >
      </v-switch>
    </v-col>
    <v-col class="d-flex justify-start align-center">
      <v-icon
        v-if="isCompact != true"
        color="#00ACCD"
      >
        mdi-brightness-3
      </v-icon>
    </v-col>
  </v-row>
</template>
<script>
import { mapState, mapGetters } from 'vuex'// import { mapGetters, mapState, mapMutations } from 'vuex'

export default {
  components: {
  },
  data: () => ({
    theme: null
  }),
  props: {
    isAbsolute: {
      type: Boolean,
      default: null
    },
    isCompact: {
      type: Boolean,
      default: null
    },
    color: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapState({
      darkMode: (state) => state.app.isDarkMode
    }),
    ...mapGetters({
    }),
    isDarkMode: {
      get () {
        return this.darkMode
      },
      set (value) {
        this.$store.dispatch('app/setDarkMode', value)
        this.$vuetify.theme.dark = value
      }
    }
  },
  methods: {
  }
}

</script>
<style scoped>
/* position absolute to prevent vuetify from messing with the position */
.hover {
  position: absolute;
  bottom: 2%;
  right: 2%;
}
</style>
<style>
  .center-switch div.v-input__control div.v-input__slot div.v-input--selection-controls__input {
    margin-right: 0px;
    margin-left: 8px;
  }
</style>
