import authenticationApi from '@/api/AuthenticationApi'
import sitesApi from '@/api/SitesApi'
import segmentsApi from '@/api/SegmentsApi'
import segmentDepthsApi from '@/api/SegmentDepthsApi'
import segmentTypesApi from '@/api/SegmentTypesApi'
import documentsApi from '@/api/DocumentsApi'
import companiesApi from '@/api/CompaniesApi'
import haulingRequestApi from '@/api/HaulingRequestsApi'
import unitOfMeasurementApi from '@/api/UnitOfMeasurementApi'
import citiesApi from '@/api/CitiesApi'
import provincesApi from '@/api/ProvincesApi'
import usersApi from '@/api/UsersApi'
import trucksApi from '@/api/TrucksApi'
import truckModelsApi from '@/api/TruckModelsApi'
import driversApi from '@/api/DriversApi'
import depthsApi from '@/api/DepthsApi'
import dispatchesApi from '@/api/DispatchesApi'
import actionsApi from '@/api/ActionsApi'

import Vue from 'vue'

const api = {
  authentication: authenticationApi,
  sites: sitesApi,
  segments: segmentsApi,
  segmentDepths: segmentDepthsApi,
  segmentTypes: segmentTypesApi,
  documents: documentsApi,
  companies: companiesApi,
  haulingRequest: haulingRequestApi,
  uom: unitOfMeasurementApi,
  cities: citiesApi,
  provinces: provincesApi,
  users: usersApi,
  trucks: trucksApi,
  truckModels: truckModelsApi,
  drivers: driversApi,
  depths: depthsApi,
  dispatches: dispatchesApi,
  actions: actionsApi
}

Vue.prototype.$api = api
export default api
