import store from '../store'
// import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

export default function checkAuth (to, from, next) {
  const isAuthenticated = store.getters['auth/isAuthenticated']
  if (to.matched.some((record) => record.meta.requiresAuth === false) || (to.matched.some((record) => record.meta.requiresAuth === true) && isAuthenticated)) {
    next()
  } else {
    store.dispatch('auth/logout').then(() => {
      next('/login')
    })
  }
}
