/* eslint-disable no-console */
import Api from '@/api/Api'

class UsersApi extends Api {
  constructor () {
    super('users', 'users')
  }
}

const usersApi = new UsersApi()
export default usersApi
