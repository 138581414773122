<template>
    <v-app-bar app class="app-bar" v-if="isAuthenticated" clipped-left elevation="0" >
        <v-row align="center" >
          <v-col class=" flex-grow-0 flex-shrink-0 pa-0 ma-0" style="min-width:162px">
              <v-row class="pa-0 ma-0">
                  <v-col class="flex-grow-0 flex-shrink-0" >
                      <v-img :src="logo" height="50" width="50"></v-img>
                  </v-col>
                  <v-col class="py-0 pr-0 pl-1 ma-0" align-self="center">
                      <v-row>SoilTrace</v-row>
                      <v-row class="mt-1 text-caption">v1.0.8</v-row></v-col>
              </v-row>
          </v-col >
          <v-col class="flex-grow-1 flex-shrink-0">
            <v-row align="center">
              <v-col class="flex-grow-0 flex-shrink-0 px-1">
                <v-icon >{{moduleIcon}}</v-icon>
              </v-col>
              <v-col class="font-weight-bold text-h6 flex-grow-1 flex-shrink-0 pl-0">
                {{moduleName}} {{ moduleNameExtension }}
              </v-col>
            </v-row>
          </v-col>
          <v-col v-if="showSiteSelector" class="flex-grow-1 flex-grow-0">
            <v-row>
              <v-col class="d-flex align-center justify-start">
                <v-select v-model="selectedSite" :items="sites" hide-details outlined dense label="Site" width="100%" item-value="id" :item-text="(item) => `${item.name} (${item.siteCode})`" />
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-text-field
              v-model="search"
              hide-details
              outlined
              dense
              prepend-inner-icon="mdi-magnify"
              variant="outlined"
              label="Search"
            />
          </v-col>
          <!-- <v-spacer /> -->
          <v-col
            class="flex-grow-1 flex-shrink-0 font-weight-bold text-h6 d-flex text-right justify-end"
            style="color:#00ACCD"
          >
            {{ company.legalName }}
          </v-col>
          <v-divider
            vertical
            class="my-2"
            color="#EFEFEF"
          />
          <v-col
            class="d-flex text-right justify-end flex-grow-0 flex-shrink-0 pr-0"
          >
            <v-btn icon @click="goToActionsPage">
              <v-icon>mdi-bell-outline</v-icon>
              <v-badge
                v-if="getActionNotificationCount > 0"
                :content="getActionNotificationCount"
                color="error"
              />
            </v-btn>
          </v-col>

          <v-col
            class="d-flex text-right justify-end flex-grow-0 flex-shrink-0 "
          >
                <the-header-menu />
            </v-col>
        </v-row>
    </v-app-bar>
</template>
<script>
import { mapState, mapGetters } from 'vuex'// import { mapGetters, mapState, mapMutations } from 'vuex';
import TheHeaderMenu from '@/components/app/TheHeaderMenu.vue'
import router from '@/router'

export default {
  components: {
    TheHeaderMenu
  },
  data: () => ({
    search: null,
    moduleName: ' ',
    moduleIcon: 'mdi-magnify',
    moduleNameExtension: '',
    haulingCompanyTypeId: 2
  }),
  created () {
  },
  watch: {
    // eslint-disable-next-line quote-props
    '$route': {
      handler (value) {
        if (this.isAuthenticated) this.$store.dispatch('site/reloadSites')
        this.moduleName = router.currentRoute.name || ' '
        this.moduleIcon = router.currentRoute.meta.icon || 'mdi-magnify'
        if (!router.currentRoute.meta.extendedName) this.moduleNameExtension = ''
      },
      deep: true,
      immediate: true
    },
    selectedCompanyProfile: {
      handler (value) {
        if (this.isAuthenticated) this.$store.dispatch('site/reloadSites')
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapState({
      logo: (state) => state.app.isNavigationMinified,
      companies: (state) => state.comp.companies,
      sites: (state) => state.site.sites,
      selectedSiteId: (state) => state.site.selectedSiteId
    }),
    ...mapGetters({
      logo: 'app/logo',
      isAuthenticated: 'auth/isAuthenticated',
      findClaim: 'auth/findClaim',
      selectedCompanyProfile: 'auth/selectedCompanyProfile',
      getActionNotificationCount: 'action/getActionNotificationCount'
    }),
    selectedSite: {
      get () {
        return this.selectedSiteId
      },
      set (id) {
        this.$store.dispatch('site/selectSite', id)
      }
    },
    isHauler () {
      const companyProfile = this.selectedCompanyProfile
      return companyProfile && companyProfile.companyTypeId === this.haulingCompanyTypeId
    },
    showSiteSelector () {
      if (this.isHauler) return false
      if (this.$route.meta && this.$route.meta.showSiteHeader) return true
      return false
    },
    claimCompanyId () {
      return this.findClaim('companyId')
    },
    company () {
      // eslint-disable-next-line eqeqeq
      const company = this.companies.find(x => x.id == this.claimCompanyId) || {}
      return company
    }
  },
  methods: {
    goToActionsPage () {
      if (this.$route.path !== '/actions') {
        this.$router.push('/actions')
      }
    }
  }
}
</script>
<style>
  .app-bar.theme--light {
    border-bottom: 1px #E0E0E0 solid !important;
    background-color: white !important;
  }
  .app-bar.theme--dark {
    border-bottom: 1px #4E4E4E solid !important;
    background-color: black !important;
  }
</style>
