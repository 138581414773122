/* eslint-disable consistent-return */
/* eslint-disable no-async-promise-executor */
/* eslint-disable no-param-reassign */
import api from '@/api/ApiFactories'
import { createPaginationObject } from '@/helpers/PaginationHelper.js'

export default {
  namespaced: true,

  state: () => ({
    sites: [],
    selectedSiteId: null
  }),
  mutations: {
    storeSites (state, sites) {
      state.sites = sites
    },
    storeSelectedSiteId (state, selectedSiteId) {
      state.selectedSiteId = selectedSiteId
    },
    clearData (state) {
      state.companies = []
      state.selectedSite = null
    }
  },
  actions: {
    async reloadSites ({ commit, state }) {
      const res = await api.sites.read(createPaginationObject(null, null, []))
      this.dispatch('site/setSites', res && res.sites ? res.sites : [])
      // if we have resulting sites, and (currently selected siteId is null or doesn't belong to the current availabl sites), update it to the first available one
      if (res && res.sites && res.sites.length && (state.selectedSiteId == null || !res.sites.some(x => x.id === state.selectedSiteId))) this.dispatch('site/selectSite', res.sites[0].id)
    },
    setSites ({ commit }, sites) {
      if (sites && sites.length) {
        commit('storeSites', sites)
      } else {
        commit('storeSelectedSiteId', null)
        commit('storeSites', [])
      }
    },
    selectSite ({ commit, state }, siteId) {
      if (state.sites.some(x => x.id === siteId)) { commit('storeSelectedSiteId', siteId) }
    }
  },
  getters: {
    selectedSite: (state) => {
      return state.sites.find(x => x.id === state.selectedSiteId)
    }
  }
}
