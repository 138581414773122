/* eslint-disable no-console */
import Api from '@/api/Api'

class SegmentDepthsApi extends Api {
  constructor () {
    super('segment-depths', 'segment-depths')
  }
}

const segmentDepthsApi = new SegmentDepthsApi()
export default segmentDepthsApi
