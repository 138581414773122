import logoWhite from '@/assets/logo-white.png'
import logoBlack from '@/assets/logo-black.png'

export default {
  namespaced: true,

  state: () => ({
    isDarkMode: false,
    isNavigationMinified: false,
    snackbar: {
      visible: false,
      message: '',
      color: ''
    }
  }),
  mutations: {
    storeDarkMode (state, isDarkMode) {
      state.isDarkMode = isDarkMode
    },
    storeNavigationMinified (state, isNavigationMinified) {
      state.isNavigationMinified = isNavigationMinified
    },
    clearData (state) {
      state.snackbar = {
        visible: false,
        message: '',
        color: ''
      }
    },
    storeSnackbar (state, snackbar) {
      state.snackbar = snackbar
    }

  },
  actions: {
    toggleNavigationMinified ({ commit, state }) {
      commit('storeNavigationMinified', !state.isNavigationMinified)
    },
    toggleDarkMode ({ commit, state }) {
      commit('storeDarkMode', !state.isDarkMode)
    },
    setDarkMode ({ commit }, mode) {
      commit('storeDarkMode', mode)
    },
    setSnackbar ({ commit }, snackbar) {
      commit('storeSnackbar', snackbar)
    },
    showSuccess ({ commit }, message) {
      commit('storeSnackbar', {
        visible: true,
        message: message,
        color: 'green'
      })
    },
    showError ({ commit }, message) {
      commit('storeSnackbar', {
        visible: true,
        message: message,
        color: 'red'
      })
    },
    showWarning ({ commit }, message) {
      commit('storeSnackbar', {
        visible: true,
        message: message,
        color: 'orange'
      })
    }
  },

  getters: {
    logo: (state) => {
      return state.isDarkMode ? logoBlack : logoWhite
    },
    getDarkMode: (state) => {
      return state.isDarkMode
    }
  }
}
