export default {
  namespaced: true,

  state: () => ({
    tenant: '',
    email: ''
  }),
  mutations: {
    storeTenant (state, data) {
      state.tenant = data
    },
    storeEmail (state, data) {
      state.email = data
    }
  },
  actions: {
    setTenant ({ commit }, data) {
      commit('storeTenant', data)
    },
    setEmail ({ commit }, data) {
      commit('storeEmail', data)
    }
  },

  getters: {
  }
}
