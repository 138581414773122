/* eslint-disable no-plusplus */
export function createPaginationObject (options = null, search = null, filters = null, include = null) {
  let Pagination = {
    Page: 1,
    ItemsPerPage: -1
  }
  const Sort = []
  if (options != null) {
    Pagination = {
      Page: options.page,
      ItemsPerPage: options.itemsPerPage
    }
    for (let index = 0; index < options.sortBy.length; index++) {
      Sort.push({
        Field: options.sortBy[index],
        IsAscending: !!options.sortDesc[index] ?? true
      })
    }
  }

  return {
    Filters: filters,
    Pagination,
    Sort,
    Search: search || '',
    Include: include || []
  }
}
