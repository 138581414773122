/* eslint-disable consistent-return */
/* eslint-disable no-async-promise-executor */
/* eslint-disable no-param-reassign */

export default {
  namespaced: true,

  state: () => ({
    user: {},
    nameId: '',
    name: ''
  }),
  mutations: {
    storeUserInfo (state, userInfo) {
      state.nameId = userInfo.nameId
      state.name = userInfo.name
    },
    clearData (state) {
      state.user = {}
      state.nameId = ''
      state.name = ''
    }
  },
  actions: {
    setUserInfo ({ commit }, userData) {
      commit('storeUserInfo', userData)
    }
  },
  getters: {
    initials: (state) => {
      return state.name.split(' ').map((name) => name[0]).join('')
    }
  }
}
