export default {
  namespaced: true,

  state: () => ({
    counter: 0
  }),
  mutations: {
    storeCounter (state, counter) {
      state.counter = counter
    }

  },
  actions: {
    increment ({ commit, state }) {
      commit('storeCounter', (state.counter + 1))
    }
  },

  getters: {
    counter: (state) => state.counter,
    doubleCount: (state) => state.counter * 2
  }
}
