import moment from 'moment'
// export default function formatDate(date: string) {
//   if (!date) return '';
//   return moment(date).format('YYYY-MM-DD');
// }
// converts string to kebab case i.e. foo-bar-baz
export function kebabCaseFormatter (str) {
  return str != null
    ? str
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .join('-')
      .toLowerCase()
    : ''
}

// convert Json to FormData
export function jsonToFormData (json) {
  const formData = new FormData()
  Object.keys(json).forEach((key) => {
    formData.append(key, json[key] === 'null' ? '' : json[key])
  })
  return formData
}

// convert Json to FormData
export function dateFromUTC (date) {
  let formattedDate = ''
  formattedDate = moment(moment.utc(date).toDate()).format('YYYY-MMM-DD, LT')
  return formattedDate
}

export function formatAddress (address) {
  if (!address) return '-'
  let formattedAddress = ''
  if (address.addressLine1) formattedAddress = address.addressLine1
  if (address.city) {
    formattedAddress = formattedAddress ? `${formattedAddress}, ${address.city.name}` : address.city.name
    if (address.city.province) {
      formattedAddress = `${formattedAddress}, ${address.city.province.shortName}${address.postalCode ? ` ${address.postalCode}` : ''}`
      if (address.city.province.country) {
        formattedAddress = `${formattedAddress}, ${address.city.province.country.name}`
      }
    }
  }
  return formattedAddress
}

export function formatPhone (phone) {
  if (!phone) return '-'
  let formattedPhone = phone.phone
  if (phone.extension) formattedPhone += ` ${phone.extension}`
  return formattedPhone
}

export function formatPhoneString (phoneStr) {
  if (!phoneStr) return ''
  // check to see phone number is 10 digits, otherwise it's not a correct phone number - might not need to do this since we have a input validator
  const integerMatches = phoneStr.match(/\d/g)
  if (integerMatches.length !== 10) {
    return ''
  }
  phoneStr = phoneStr.replace(/\D/g, '')
  const match = phoneStr.match(/(\d{3})(\d{3})(\d{4})/)
  const formattedPhoneStr = `(${match[1]}) ${match[2]}-${match[3]}`
  return formattedPhoneStr
}
