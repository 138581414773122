import Vue from 'vue'
import VueRouter from 'vue-router'
import checkAuth from '@/middleware/auth'

Vue.use(VueRouter)

const generalContractorCompanyTypeId = 1
const haulerCompanyTypeId = 2
const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    meta: {
      requiresAuth: false,
      layout: 'AppLayoutLogin',
      icon: 'mdi-login'
    }
  },
  {
    path: '/dashboards',
    name: 'Dashboards',
    component: () => import('@/views/dashboards/Dashboards.vue'),
    meta: {
      requiresAuth: true,
      icon: 'mdi-view-dashboard-outline'
    }
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('@/views/search/Search.vue'),
    meta: {
      requiresAuth: true,
      icon: 'mdi-magnify'
    }
  },
  {
    path: '/sites',
    name: 'Sites',
    component: () => import('@/views/sites/Sites.vue'),
    meta: {
      requiresAuth: true,
      icon: 'mdi-office-building-marker-outline',
      defaultPageFor: [generalContractorCompanyTypeId, haulerCompanyTypeId]
    }
  },
  {
    path: '/site',
    name: 'Site',
    component: () => import('@/views/sites/Site.vue'),
    meta: {
      requiresAuth: true,
      icon: 'mdi-office-building-marker-outline',
      extendedName: true,
      showSiteHeader: true
    }
  },
  {
    path: '/actions',
    name: 'Action Center',
    component: () => import('@/views/action/Actions.vue'),
    meta: {
      requiresAuth: true,
      icon: 'mdi-alert'
    }
  },
  {
    path: '/segments',
    name: 'Segments',
    component: () => import('@/views/segments/Segments.vue'),
    meta: {
      requiresAuth: true,
      icon: 'mdi-rhombus-split-outline',
      restrictedToCompanyTypes: [generalContractorCompanyTypeId],
      showSiteHeader: true
    }
  },
  {
    path: '/hauls',
    name: 'Haul Requests',
    component: () => import('@/views/hauls/Hauls.vue'),
    meta: {
      requiresAuth: true,
      icon: 'mdi-truck-fast-outline',
      showSiteHeader: true
    }
  },
  {
    path: '/trucks',
    name: 'Trucks',
    component: () => import('@/views/hauls/Trucks.vue'),
    meta: {
      requiresAuth: true,
      icon: 'mdi-dump-truck',
      restrictedToCompanyTypes: [haulerCompanyTypeId]
    }
  },
  {
    path: '/drivers',
    name: 'Drivers',
    component: () => import('@/views/hauls/Drivers.vue'),
    meta: {
      requiresAuth: true,
      icon: 'mdi-steering',
      restrictedToCompanyTypes: [haulerCompanyTypeId]
    }
  },
  {
    path: '/dispatch',
    name: 'Dispatch',
    component: () => import('@/views/hauls/Dispatches.vue'),
    meta: {
      requiresAuth: true,
      icon: 'mdi-cellphone-basic',
      restrictedToCompanyTypes: [haulerCompanyTypeId]
    }
  },
  {
    path: '/documents',
    name: 'Documents',
    component: () => import('@/views/documents/Documents.vue'),
    meta: {
      requiresAuth: true,
      icon: 'mdi-file-document-multiple'
    }
  },
  {
    path: '/reports',
    name: 'Reports',
    component: () => import('@/views/reports/Reports.vue'),
    meta: {
      requiresAuth: true,
      icon: 'mdi-file-chart-outline'
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('@/views/profile/Profile.vue'),
    meta: {
      requiresAuth: true,
      icon: 'mdi-account-circle'
    }
  },
  {
    path: '/company',
    name: 'Company',
    component: () => import('@/views/company/Company.vue'),
    meta: {
      requiresAuth: true,
      icon: 'mdi-domain'
    }
  },
  {
    path: '/companies',
    name: 'Companies',
    component: () => import('@/views/company/Companies.vue'),
    meta: {
      requiresAuth: true,
      icon: 'mdi-domain'
    }
  },
  {
    path: '/Admin',
    name: 'Admin',
    component: () => import('@/views/admin/Admin.vue'),
    meta: {
      requiresAuth: true,
      icon: 'mdi-cog-outline'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  checkAuth(to, from, next)
})

export default router
