/* eslint-disable no-console */
import Api from '@/api/Api'

class CitiesApi extends Api {
  constructor () {
    super('cities', 'cities')
  }
}

const citiesApi = new CitiesApi()
export default citiesApi
