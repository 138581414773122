/* eslint-disable no-console */
import Api from '@/api/Api'

class DriversApi extends Api {
  constructor () {
    super('drivers', 'drivers')
  }
}

const driversApi = new DriversApi()
export default driversApi
