<template>
  <v-app>
    <the-header ref="theHeader" :moduleNameExtension="moduleNameExtension" />
    <the-navigation-drawer />
    <v-snackbar
      top
      style="margin-top: 60px"
      v-model="snackbar"
      :color="storeSnackbar.color"
    >
      {{ storeSnackbar.message }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
    <the-snackbar />
    <the-loading />
    <v-main>
      <router-view @updateModuleNameExtension="(value) => $refs.theHeader.moduleNameExtension = value"/>
    </v-main>
  </v-app>
</template>

<script>

import TheSnackbar from '@/components/app/TheSnackbar.vue'
import TheLoading from '@/components/app/TheLoading.vue'
import TheHeader from '@/components/app/TheHeader.vue'
import TheNavigationDrawer from '@/components/app/TheNavigationDrawer.vue'
import { mapState, mapGetters } from 'vuex'// import { mapGetters, mapState, mapMutations } from 'vuex';
// import AppLayout from '@/layouts/AppLayout.vue'
// import { useTheme } from 'vuetify'

export default {
  name: 'App',
  // components thesnackbar
  components: {
    TheSnackbar,
    TheLoading,
    TheHeader,
    TheNavigationDrawer
    // AppLayout
  },
  data: () => ({
    theme: null,
    moduleNameExtension: '',
    customNameModules: ['Site']
  }),
  computed: {
    ...mapState({
      darkMode: (state) => state.app.isDarkMode,
      claims: (state) => state.auth.claims,
      storeSnackbar: (state) => state.app.snackbar
    }),
    ...mapGetters({
      selectedCompanyProfile: 'auth/selectedCompanyProfile'
    }),
    isDarkMode () {
      return this.darkMode
    },
    snackbar: {
      get () {
        return this.storeSnackbar.visible
      },
      set (value) {
        const updatedSnackbar = { ...this.storeSnackbar, visible: value }
        this.$store.dispatch('app/setSnackbar', updatedSnackbar)
      }
    }
  },
  watch: {
    claims: {
      handler (value) {
        // claims have changed
        if (value && Object.keys(value).length) {
          // check to see if the current route has any claim-related restriction
          if (this.$route.meta && this.$route.meta.restrictedToCompanyTypes && this.$route.meta.restrictedToCompanyTypes.length) {
            // since it does, check to make sure that the current claim is allowed access to the current route
            if (!this.$route.meta.restrictedToCompanyTypes.some(x => x === this.selectedCompanyProfile.companyTypeId)) {
              // since it's not, let's redirect to first available valid route
              const defaultRoute = this.$router.options.routes.find(route => route.meta.defaultPageFor != null && route.meta.defaultPageFor.some(companyTypeId => companyTypeId === this.selectedCompanyProfile.companyTypeId))
              this.$router.push(defaultRoute.path)
            }
          }
        }
      }
    },
    darkMode: {
      immediate: true,
      handler (value) {
        this.$vuetify.theme.dark = value
      }
    }
  }
}
</script>
