/* eslint-disable no-console */
import Api from '@/api/Api'

class SegmentTypesApi extends Api {
  constructor () {
    super('segment-types', 'segment-types')
  }
}

const segmentTypesApi = new SegmentTypesApi()
export default segmentTypesApi
