/* eslint-disable no-console */
import Api from '@/api/Api'

class DispatchesApi extends Api {
  constructor () {
    super('dispatches', 'dispatches')
  }
}

const dispatchesApi = new DispatchesApi()
export default dispatchesApi
