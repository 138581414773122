import Vue from 'vue'
import Vuex from 'vuex'
import SecureLS from 'secure-ls'
import CreatePersistedState from 'vuex-persistedstate'

import app from './app.module'
import auth from './auth.module'
import counter from './counter.module'
import login from './login.module'
import user from './user.module'
import comp from './comp.module'
import site from './site.module'
import types from './types.module'
import action from './action.module'

const ls = new SecureLS({ isCompression: false })

Vue.use(Vuex)

const vuexPersisted = new CreatePersistedState({
  key: 'boardMatch',
  storage: {
    getItem: (key) => ls.get(key),
    setItem: (key, value) => ls.set(key, value),
    removeItem: (key) => ls.remove(key)
  }
})

export default new Vuex.Store({
  plugins: [vuexPersisted],
  modules: {
    app,
    auth,
    counter,
    login,
    user,
    comp,
    site,
    types,
    action
  }
})
