/* eslint-disable no-console */
import Api from '@/api/Api'

class ActionsApi extends Api {
  constructor () {
    super('actions', 'actions')
  }
}

const actionsApi = new ActionsApi()
export default actionsApi
