/* eslint-disable no-console */
import Api from '@/api/Api'
import axios from 'axios'

class SitesApi extends Api {
  constructor () {
    super('documents', 'documents')
  }

  async upload (entityType, entityId, file, onUploadProgress) {
    // eslint-disable-next-line prefer-const
    let formData = new FormData()
    formData.append('Content', file)

    let errorText = 'Could not upload file'
    let res
    try {
      res = await axios.post(`${this.endpoint}/upload/${entityType}/${entityId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress
      })
      if (res.status === 200) {
        res = res.data
      } else if (res.status === 204) {
        errorText = 'Could not upload file'
        console.error(errorText)
      } else {
        console.error(errorText)
        res = false
      }
    } catch (e) {
      console.error(errorText)
      console.error(e)
      res = false
    }
    return res
  }

  async uploadMultiple (entityType, entityId, files, onUploadProgress) {
    // eslint-disable-next-line prefer-const
    let formData = new FormData()
    files.forEach(file => {
      formData.append('Content', file)
    })

    let errorText = 'Could not upload file'
    let res
    try {
      res = await axios.post(`${this.endpoint}/upload-multiple/${entityType}/${entityId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress
      })
      if (res.status === 200) {
        res = res.data
      } else if (res.status === 204) {
        errorText = 'Could not upload file'
        console.error(errorText)
      } else {
        console.error(errorText)
        res = false
      }
    } catch (e) {
      console.error(errorText)
      console.error(e)
      res = false
    }
    return res
  }

  async downloadDocument (id, fileName, type) {
    let list
    const errorText = `Could not fetch ${this.model} list`
    try {
      const url = `/${this.endpoint}/download/${id}`
      list = await axios({
        method: 'get',
        url: url,
        responseType: 'blob'
      })
      if (list.status === 200) {
        list = list.data
        this.openFileType(list, fileName, `application${type.toLowerCase().includes('pdf') ? '/pdf' : ''}`)
      } else {
        console.error(errorText)
      }
    } catch (e) {
      console.error(errorText)
      console.error(e)
      list = []
    }
    return list
  }

  openFileType (resData, fileName, fileType) {
    var ieEDGE = navigator.userAgent.match(/Edge/g)
    var ieNewEDGE = navigator.userAgent.match(/Edg/g)
    var ie = navigator.userAgent.match(/.NET/g) // IE 11+
    var oldIE = navigator.userAgent.match(/MSIE/g)
    // var bytes = new Uint8Array(resData); //use this if data is raw bytes else directly pass resData
    if (ie || oldIE || ieEDGE) {
      var blob = new window.Blob([resData], { type: fileType })
      window.navigator.msSaveBlob(blob, fileName)
    } else if (ieNewEDGE) {
      var objectUrl = URL.createObjectURL(resData)
      window.open(objectUrl)
    } else {
      var file = new Blob([resData], { type: fileType })
      var fileURL = URL.createObjectURL(file)

      // create <a> tag dinamically
      var fileLink = document.createElement('a')
      fileLink.href = fileURL

      // it forces the name of the downloaded file
      fileLink.download = fileName

      // triggers the click event
      fileLink.click()
    }
  }
}

const sitesApi = new SitesApi()
export default sitesApi
