import axios from 'axios'
import store from '../store'
import Vue from 'vue'

// const url: URL = new URL(location.href);
// const host: string[] = url.host.split('.');
// const tenant = host.length == 2 ? host[0] : '';
const baseUrl = `${process.env.VUE_APP_API_URL_PROTOCOL}://${process.env.VUE_APP_API_URL_HOSTNAME}:${process.env.VUE_APP_API_URL_PORT}`

axios.defaults.baseURL = baseUrl
axios.defaults.headers.common.Accept = 'application/json'

axios.interceptors.request.use((request) => {
  const token = store.getters['auth/userToken']
  request.headers.Authorization = `Bearer ${token}`
  return request
})

Vue.$http = axios
Object.defineProperty(Vue.prototype, '$http', {
  get () {
    return axios
  }
})
export default axios
