/* eslint-disable no-console */
import Api from '@/api/Api'

class TruckModelsApi extends Api {
  constructor () {
    super('truckModels', 'truckModels')
  }
}

const truckModelsApi = new TruckModelsApi()
export default truckModelsApi
