/* eslint-disable no-console */
import Api from '@/api/Api'

class TrucksApi extends Api {
  constructor () {
    super('trucks', 'trucks')
  }
}

const trucksApi = new TrucksApi()
export default trucksApi
