import api from '@/api/ApiFactories'

export default {
  namespaced: true,

  state: () => ({
    actionNotificationCount: 0
    // recentActions: [],
  }),
  mutations: {
    storeActionNotificationCount (state, count) {
      state.actionNotificationCount = count
    }
  },
  actions: {
    async refreshActionCount ({ commit }) {
      const res = await api.actions.get('notifications/count')
      if (res >= 0) {
        commit('storeActionNotificationCount', res)
      }
    }
  },
  getters: {
    getActionNotificationCount: (state) => state.actionNotificationCount
  }
}
