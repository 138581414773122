<template>
  <v-menu
    bottom
    min-width="200px"
    rounded
    offset-y
    :close-on-content-click="true"
  >
    <template v-slot:activator="{ on, attrs }">
      <div class="d-flex"
          v-bind="attrs"
          v-on="on">
        <v-btn
          icon
        >
          <v-avatar
            color="grey"
            size="42"
          >
            <span class="white--text text-h6">{{ user.initials }}</span>
          </v-avatar>
        </v-btn>
        <v-icon
          class="align-self-center"
        >
          mdi-menu-down
        </v-icon>
      </div>
    </template>
    <v-card>
      <v-card-text>
        <div class="mx-auto text-center">
          <v-avatar
            color="grey"
            size="76"
          >
            <span class="white--text text-h4">{{ user.initials }}</span>
          </v-avatar>
          <h3>{{ user.fullName }}</h3>
          <p class="text-caption mt-1">
            {{ user.email }}
          </p>
          <v-divider class="mt-2 mb-2" />
          <div v-for="company in companies" :key="`comp-${company.id}`">
            <div class="d-flex align-center justify-center">
              <v-icon
                    color="#00ACCD"
                    class="mr-2"
                  >
                  mdi-domain
              </v-icon>
              <span class="text-h6">{{ company.name }}</span>
            </div>
            <v-list-item-group
              v-model="selectedPOV"
              value="id"
              color="primary"
            >
              <v-list-item
                v-for="(item, index) in company.companyProfiles"
                :key="`pov-menu-item-${index}`"
                :to="item.link"
                :value="item"
                variant="plain"
                @click="menuActionClick(item.action)"
                selected
              >
                <v-list-item-title
                  class="pl-2 d-flex justify-start"
                  style="font-size:15px;"
                >
                  {{ item.companyType.name }}
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </div>
          <v-divider class="mt-2" />
          <v-list-item
            v-for="(item, index) in menuItems"
            :key="`settings-menu-item-${index}`"
            :to="item.link"
            :value="item"
            variant="plain"
            @click="menuActionClick(item.action)"
          >
            <v-list-item-title
              class="pl-2 d-flex justify-start"
              style="font-size:15px;"
            >
              <v-icon
                color="#00ACCD"
                style="font-size:16px"
                class="mr-2"
              >
                {{ item.icon }}
              </v-icon>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
          <!-- <v-btn
            rounded
            variant="text"
            class="mx-auto"
            size="small"
          >
            Profile
          </v-btn> -->
          <v-divider class="mb-2" />
          <the-theme-switch />
          <v-divider class="mt-1" />
          <v-list-item
            v-for="(item, index) in menuBottomItems"
            :key="`menu-bottom-item-${index}`"
            :to="item.link"
            :value="item"
            variant="plain"

            @click="menuActionClick(item.action)"
          >
            <v-list-item-title
              class="pl-2 d-flex justify-start"
              style="font-size:15px;"
            >
              <v-icon
                color="#00ACCD"
                style="font-size:16px"
                class="mr-2"
              >
                {{ item.icon }}
              </v-icon>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>
<script>
import router from '@/router'
import TheThemeSwitch from '@/components/app/TheThemeSwitch.vue'
import { createPaginationObject } from '@/helpers/PaginationHelper.js'
import { mapState, mapGetters } from 'vuex'// import { mapGetters, mapState, mapMutations } from 'vuex';

export default {
  components: {
    TheThemeSwitch
  },
  data: () => ({
  }),
  computed: {
    ...mapState({
      name: (state) => state.user.name,
      nameId: (state) => state.user.nameId,
      companies: (state) => state.comp.companies
    }),
    ...mapGetters({
      initials: 'user/initials',
      findClaim: 'auth/findClaim'
    }),
    selectedPOV: {
      get () {
        // eslint-disable-next-line eqeqeq
        const selectedPov = this.companies.find(x => x.id == this.claimCompanyId).companyProfiles.find(x => x.id == this.claimCompanyProfileId)

        return selectedPov
      },
      set (value) {
        this.$store.dispatch('auth/setPov', value)
      }
    },
    claimCompanyId () {
      return this.findClaim('companyId')
    },
    claimCompanyProfileId () {
      return this.findClaim('companyProfileId')
    },
    user () {
      return {
        initials: this.initials,
        fullName: this.name,
        email: this.nameId
      }
    },
    menuItems () {
      const items = []
      items.push({
        icon: 'mdi-account-circle',
        title: 'Profile',
        link: '/profile',
        action: ''
      }/*,
      {
        icon: 'mdi-domain',
        title: 'Company',
        link: '/company',
        action: ''
      } */)

      // todo: check admin
      items.push({
        icon: 'mdi-cog-outline',
        title: 'Admin',
        link: '/admin',
        action: ''
      })
      return items
    },
    menuBottomItems () {
      const items = []
      items.push({
        icon: 'mdi-logout',
        title: 'Logout',
        link: '',
        action: 'logout'
      })
      return items
    }
  },
  watch: {
    // eslint-disable-next-line quote-props
    claimCompanyProfileId: {
      handler (value) {
        if (value) this.loadAvailablePOVs()
      },
      deep: true,
      immediate: true
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      // this.loadAvailablePOVs()
    },
    logout () {
      this.$store.dispatch('auth/logout')
      router.push('/login')
    },
    menuActionClick (action) {
      if (action === 'logout') this.logout()
    },
    async loadAvailablePOVs () {
      const search = createPaginationObject(null, null, [{ name: 'Own', values: [] }])
      const res = await this.$api.companies.read(search)
      if (res && res.companies && res.companies.length) {
        await this.$store.dispatch('comp/setCompanies', res.companies)
      }
    }
  }
}
</script>
<style>

</style>
