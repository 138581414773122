/* eslint-disable no-console */
import Api from '@/api/Api'

class SitesApi extends Api {
  constructor () {
    super('sites', 'sites')
  }
}

const sitesApi = new SitesApi()
export default sitesApi
