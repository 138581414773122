
<template>
  <v-navigation-drawer
    v-if="isAuthenticated"
    v-model="drawer"
    app
    width="155"
    active-color="#00ACCD"
    absolute
    disable-resize-watcher
    :border="0"
    :rail="isNavigationMinified"
    style="padding-top: 64px"
  >
    <!-- Top Items -->
    <v-list
      nav
      density="compact"
    >
      <v-list-item
        v-for="(item, index) in navigationItemsTop"
        :key="index"
        :to="item.link"
        :value="item"
        variant="plain"
        color="#00ACCD"
      >
        <!-- @click="menuActionClick(item.action)" -->
        <template #prepend>
          <v-row style="max-width:50px;">
            <!-- <v-col
              :style="path == item.link ? `border:1.5px #00ACCD solid; ` : 'border:1.5px transparent solid'"
              style="min-height:20px; max-width:1px; width:1px;"
              class="align-self-center pa-0 ma-0 "
            /> -->
            <v-divider vertical v-show="path == item.link" class="my-1" color="#00ACCD"></v-divider>
            <v-col class="pl-3 py-1">
              <v-icon
                :icon="item.icon"
                style="font-size:16px"
              />
            </v-col>
          </v-row>
        </template>
        <v-list-item-title
          v-if="!isNavigationMinified"
          class="pl-0"
          style="font-size:15px;"
        >
          {{ item.title }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
    <template #append>
      <div class="pa-2">
        <v-btn
          icon
          text
          @click="toggleNavigationMinified()"
        >
          <v-icon
            color="#00ACCD"
            :icon="isNavigationMinified? 'mdi-chevron-right': 'mdi-chevron-left'"
          />
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapGetters } from 'vuex'// import { mapGetters, mapState, mapMutations } from 'vuex';
export default {
  components: {
  },
  data: () => ({
    drawer: true,
    generalContractorCompanyTypeId: 1,
    driverCompanyTypeId: 2
  }),
  computed: {
    ...mapState({
      isNavigationMinified: (state) => state.app.isNavigationMinified,
      companies: (state) => state.comp.companies
    }),
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      findClaim: 'auth/findClaim'
    }),
    currentCompanyProfileId () {
      return this.findClaim('companyProfileId')
    },
    currentCompanyProfile () {
      // eslint-disable-next-line eqeqeq
      const company = this.companies.find(comp => comp.companyProfiles.some(prof => prof.id == this.currentCompanyProfileId))
      // eslint-disable-next-line eqeqeq
      return company ? company.companyProfiles.find(x => x.id == this.currentCompanyProfileId) : null
    },
    path () {
      return this.$route.path
    },
    navigationItemsTop () {
      const items = []
      const companyTypeId = this.currentCompanyProfile ? this.currentCompanyProfile.companyTypeId : 0

      items.push(
        {
          icon: 'mdi-office-building-marker-outline',
          title: 'Sites',
          link: '/sites',
          action: ''
        }
      )
      if (companyTypeId === this.generalContractorCompanyTypeId) {
        items.push(
          {
            icon: 'mdi-rhombus-split-outline',
            title: 'Segments',
            link: '/segments',
            action: ''
          }
        )
      }
      items.push(
        {
          icon: 'mdi-truck-fast-outline',
          title: 'Haul Requests',
          link: '/hauls',
          action: ''
        }
      )
      items.push(
        {
          icon: 'mdi-file-chart-outline',
          title: 'Reports',
          link: '/reports',
          action: ''
        }
      )
      items.push(
        {
          icon: 'mdi-domain',
          title: 'Companies',
          link: '/companies',
          action: ''
        }
      )
      if (companyTypeId === this.driverCompanyTypeId) {
        items.push(
          {
            icon: 'mdi-cellphone-basic',
            title: 'Dispatch',
            link: '/dispatch',
            action: ''
          }
        )
      }
      if (companyTypeId === this.driverCompanyTypeId) {
        items.push(
          {
            icon: 'mdi-dump-truck',
            title: 'Trucks',
            link: '/trucks',
            action: ''
          }
        )
      }
      if (companyTypeId === this.driverCompanyTypeId) {
        items.push(
          {
            icon: 'mdi-steering',
            title: 'Drivers',
            link: '/drivers',
            action: ''
          })
      }
      items.push(
        {
          icon: 'mdi-alert',
          title: 'Action Center',
          link: '/actions',
          action: ''
        })

      return items
    }
  },
  methods: {
    toggleNavigationMinified () {
      this.$store.dispatch('app/toggleNavigationMinified')
    }
  }
}

</script>
<style scoped >
.v-divider--vertical{
  border-width: 1.5px;
}
</style>
