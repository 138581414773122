/* eslint-disable no-console */
import Api from '@/api/Api'

class DepthsApi extends Api {
  constructor () {
    super('depths', 'depths')
  }
}

const depthsApi = new DepthsApi()
export default depthsApi
