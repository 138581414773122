import Vue from 'vue'
import moment from 'moment'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@/api/ApiFactories.js'
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete'
import TheRedAsterisk from '@/components/app/TheRedAsterisk'

Vue.config.productionTip = false
Vue.prototype.$bus = new Vue()
Vue.prototype.$moment = moment
Vue.component('red-asterisk', TheRedAsterisk)

Vue.use(VuetifyGoogleAutocomplete, {
  apiKey: 'AIzaSyCyKvCdAy-uQjyitnQhf3D48HyKZbewD0I'
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
