/* eslint-disable no-console */
import Api from '@/api/Api'

class ProvincesApi extends Api {
  constructor () {
    super('provinces', 'provinces')
  }
}

const provincesApi = new ProvincesApi()
export default provincesApi
