// import type LoginSubmit from '@/models/login/LoginSubmit';
import axios from 'axios'
import api from '@/api/ApiFactories'

export default {
  namespaced: true,

  state: () => ({
    token: '',
    refreshToken: '',
    regMessage: '',
    claims: {}
    // selectedPov: {}
  }),
  mutations: {
    storeJwtToken (state, token) {
      state.token = token
    },
    storeClaims (state, claims) {
      state.claims = claims
    },
    deleteRegMessage (state) {
      state.regMessage = ''
    },
    /* storeSelectedPov (state, selectedPov) {
      state.selectedPov = selectedPov
    }, */
    clearData (state) {
      state.token = ''
      state.refreshToken = ''
      state.regMessage = ''
      state.claims = {}
      axios.defaults.headers.common.Authorization = ''
      this.commit('site/clearData')
      this.commit('user/clearData')
      this.commit('comp/clearData')
      this.commit('types/clearData')
      // state.selectedPov = {}
    }

  },
  actions: {
    async login ({ commit }, data) {
      try {
        const authResponse = await api.authentication.authenticate(data.username, data.password)
        return await this.dispatch('auth/processAuthResponse', authResponse)
        // this.processAuthResponse(commit, authResponse)
      } catch (e) {
        return e
      }
    },
    async setPov ({ commit }, companyProfile) {
      const authResponse = await api.authentication.post({ companyId: companyProfile.companyId, companyProfileId: companyProfile.id }, 'switch')
      return await this.dispatch('auth/processAuthResponse', authResponse)
      // this.processAuthResponse(commit, authResponse)
      // commit('storeSelectedPov', companyProfile)
    },
    async processAuthResponse ({ commit }, authResponse) {
      if (typeof authResponse === 'string') {
        this.dispatch(
          'app/showError', authResponse
        )
        return false
      } else if (authResponse.errors) {
        this.dispatch(
          'app/showError', authResponse.errors[Object.keys(authResponse.errors)[0]][0]
        )
        return false
      }
      const { token } = authResponse
      let parsedToken = null
      try {
        parsedToken = JSON.parse(atob(token.split('.')[1]))
      } catch (e) {
        // eslint-disable-next-line no-throw-literal
        throw false
      }
      if (!parsedToken) {
        // eslint-disable-next-line no-throw-literal
        throw false
      }

      // set state
      commit('storeJwtToken', token)
      commit('storeClaims', parsedToken)
      await this.dispatch('comp/loadCompanies')
      // this.refreshToken = refreshToken;
      this.dispatch('user/setUserInfo', { nameId: parsedToken.nameid, name: parsedToken.name })
      this.dispatch('types/loadUnitsOfMeasurement')

      // set axios authorization header
      axios.defaults.headers.common.Authorization = `Bearer ${token}`
      return true
    },
    logout ({ commit }) {
      commit('clearData')
    }
  },

  getters: {
    isAuthenticated: (state) => {
      return state.token !== ''
    },
    userToken: (state) => {
      return state.token
    },
    findClaim: (state) => (claimName) => {
      if (claimName in state.claims) {
        const claim = state.claims[claimName]
        if (claim != null || claim !== '') {
          return claim
        }
      }
      return null
    },
    selectedCompanyProfile: (state, getters, rootState) => {
      const companies = rootState.comp.companies
      const companyId = state.claims.companyId
      // eslint-disable-next-line eqeqeq
      const company = companies.find(x => x.id == companyId)
      if (!company) return null
      const companyProfileId = state.claims.companyProfileId
      // eslint-disable-next-line eqeqeq
      const companyProfile = company.companyProfiles && companyProfileId ? company.companyProfiles.find(cp => cp.id == companyProfileId) : null
      return companyProfile
    }
  }
}
