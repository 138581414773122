/* eslint-disable no-console */
import Api from '@/api/Api'

class SegmentsApi extends Api {
  constructor () {
    super('segments', 'segments')
  }
}

const segmentsApi = new SegmentsApi()
export default segmentsApi
