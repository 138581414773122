/* eslint-disable no-console */
import Api from '@/api/Api'

class UnitOfMeasurementApi extends Api {
  constructor () {
    super('unit-of-measurement', 'unit-of-measurement')
  }
}

const unitOfMeasurementApi = new UnitOfMeasurementApi()
export default unitOfMeasurementApi
